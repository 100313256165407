import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface FourZeroFourIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const FourZeroFourIcon = React.memo<FourZeroFourIconProps>(
  ({color = 'outlines'}) => (
    <svg viewBox="0 0 518 191" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M107.939 190.991V144.159H142.331V123.228H107.939V7.84891H76.9857L0 121.92V144.159H84.9224V190.991H107.939ZM21.9581 123.228C23.81 120.089 25.9265 116.687 28.0429 114.071L76.9857 41.076L84.9224 28.5177V123.228H21.9581Z"
        fill={colors[color] || color}
      />
      <path
        d="M483.608 190.991V144.159H518V123.228H483.608V7.84891H452.655L375.669 121.92V144.159H460.591V190.991H483.608ZM397.627 123.228C399.479 120.089 401.596 116.687 403.712 114.071L452.655 41.076L460.591 28.5177V123.228H397.627Z"
        fill={colors[color] || color}
      />
      <path
        d="M326.741 0H187.82V22.7188H326.741V0Z"
        fill={colors[color] || color}
      />
      <path
        d="M330.775 163.886H353.858V27.1139H330.775V163.886Z"
        fill={colors[color] || color}
      />
      <path
        d="M160.73 163.886H183.807V27.1139H160.73V163.886Z"
        fill={colors[color] || color}
      />
      <path
        d="M187.817 168.267V191H326.736V168.267H187.817Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
