import React from 'react';
import Head from 'next/head';
import {
  Box,
  FourZeroFourIcon,
  Typography,
  UnstyledHtmlAnchor,
} from '@ttx/design-system';
import {NextPageWithPageLayoutOptions} from '../components/page-layout/types';
import {BorderedSection} from '../components/bordered-section';

const PageNotFound: NextPageWithPageLayoutOptions = () => {
  return (
    <Box flex="1" alignItems="center" justifyContent="center">
      <Head>
        <title>404 Not Found</title>
      </Head>
      <BorderedSection
        flex="1"
        justifyContent="center"
        paddingX="four"
        alignItems="center"
      >
        <Box m="eight" maxW={[350, 600]}>
          <Box>
            <FourZeroFourIcon />
          </Box>
          <Box mt="seventeen">
            <Typography color="textDefault" textStyle="m" textAlign="center">
              This page could not be found. Return to TokenTraxx{' '}
              <UnstyledHtmlAnchor href="/">
                <Typography
                  color="textDefault"
                  textDecoration="underline"
                  textStyle="m"
                >
                  Home
                </Typography>
              </UnstyledHtmlAnchor>
              .
            </Typography>
          </Box>
        </Box>
      </BorderedSection>
    </Box>
  );
};

PageNotFound.pageLayoutOptions = {
  showFooter: false,
  showNavbar: true,
};

export default PageNotFound;
